<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="4" md="2">
        <v-select
            :items="historyChartTypes"
            item-value="id"
            v-model="historyChartType"
            label="Chart Type"
        />
      </v-col>
      <v-col cols="8" md="10">
        <div class="text-h5">{{ sliderLabel }}</div>
        <v-slider
            v-model="month"
            step="1"
            ticks="always"
            tick-size="4"
            :max="sliderMax">
        </v-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <DashChart class="chart" :data="historyChartData" :type="historyChartType" :options="chartOptions"/>
      </v-col>
      <v-col cols="6" md="2">
        <v-tabs
            v-model="tab"
            color="primary"
            :vertical="true"
        >
          <v-tab v-for="cat in filteredCategories" :key="cat.id" :tab-value="cat.id" >
            <v-icon left>
              mdi-folder
            </v-icon>
            {{cat.description}}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="6">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="cat in filteredCategories" :key="cat.id" :value="cat.id">
            <div class="text-h5">{{cat.description}}</div>
            <v-data-table
                class="mt-5"
                :headers="tableHeaders"
                :items="currentTabData"
                sort-by="description"
                :sort-desc="false"
                item-key="catId"
                hide-default-footer
            >
            </v-data-table>

            <v-card elevation="6" outlined>
              <v-card-title><div class="text-center">Total</div></v-card-title>
              <v-card-subtitle>{{ formattedTotalAmount }}</v-card-subtitle>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {hex2rgb} from "../../services/color-functions";
import DashChart from "./Charts/DashChart";
import {formatAsCurrency} from "@/services/utils";

export default {
  name: "ExpensesTab",
  components: {DashChart},

  data() {
    return {
      month: 0,
      historyChartType: 'donut',
      historyChartTypes: [
        {id: 'donut', text: 'Donut'},
        {id: 'pie', text: 'Pie'},
      ],
      chartColors: [
        "#ff0919",
        "#00ff24",
        "#fff500",
        "#1400ff",
        "#ff00d0",
        "#ff7600",
        "#00f5ff",
        "#bd11ff",
        "#cccccc",
        "#8eccc5",
        "#3b3a54"
      ],
      tab: 1,
      tableHeaders: [
        {
          value: 'description',
          text: 'Category'
        }, {
          value: 'amount',
          text: 'Amount',
          align: 'end'
        },
      ],
    }
  },

  beforeCreate() {
    this.$store.dispatch('fetchExpensesCategories').then(() => {
      this.$store.dispatch('fetchExpensesBySubCategories').then(() => {
        this.$store.dispatch('fetchExpensesByCategories').then(() => {
          this.month = this.labels.length - 1;
          this.tab = this.categories[0].id;
        });
      });
    });
  },

  computed: {
    chartOptions() {
      return {
        elements: {
          center: {
            text: "Total: $10 000",
            color: "#CCCCCC"
          }
        }
      }
    },

    historyData() {
      return this.$store.state.charts.expenses.data || [];
    },

    labels() {
      return this.$store.state.charts.expenses.labels;
    },

    labelsCount() {
      return this.labels.length;
    },

    sliderMax() {
      return this.labels.length - 1; //this.$store.state.charts.history.labels.length || 0;
    },

    selectedLabel() {
      return (this.labelsCount > 0 && this.month > 0)
          ? this.labels[this.month]
          : {label: '', m: 0, y: 0};
    },

    sliderLabel() {
      return typeof(this.selectedLabel) !== 'undefined' ? this.selectedLabel.label : 0;
    },

    filteredHistoryData() {
      return this.historyData.filter((h) => {
        return h.month == this.selectedLabel.m && h.year == this.selectedLabel.y
      }).map((h) => {
        return {
          label: h.label,
          color: h.color,
          data: h.data
        }
      });
    },

    expenseChartDatasets() {
      let dataset = [], colors = [];

      this.filteredHistoryData.forEach((category, index) => {
        let color = category.color ? category.color : this.chartColors[index];
        dataset.push(category.data);
        colors.push(hex2rgb(color, 0.6).css)
      });

      return [{
        label: 'Categories',
        backgroundColor: colors,
        data: dataset
      }];
    },

    historyChartLabels() {
      return this.historyData.filter((h) => {
        return h.month == this.selectedLabel.m && h.year == this.selectedLabel.y
      }).map((h) => h.label);
    },

    historyChartData() {
      return {
        datasets: this.expenseChartDatasets,
        labels: this.historyChartLabels,
      }
    },

    categories() {
      return this.$store.state.expenses.categories;
    },

    filteredCategories() {
      return this.categories.filter((c) => {
        return this.subCategories.findIndex((sc) => sc.parentId == c.id) > -1;
      })
    },

    subCategoriesData() {
      return this.$store.state.charts.expenseBySub.data;
    },

    subCategories() {
      return this.subCategoriesData.filter((sc) => {
        return sc.month == this.selectedLabel.m && sc.year == this.selectedLabel.y
      }).map((cat) => {
        return {
          catId: cat.category_id,
          parentId: cat.parent_id,
          description: cat.category_name
        }
      }).map((cat) => {
        cat.raw_amount = this.subCategoriesData.filter((scd) => {
            return scd.month == this.selectedLabel.m && scd.year == this.selectedLabel.y && scd.category_id == cat.catId
          }).reduce((n, {amount}) => n + amount, 0);
        cat.amount = formatAsCurrency(cat.raw_amount.toFixed(2));

        return cat;
      });
    },

    uniqueSubCatIds() {
      return Array.from(new Set(this.subCategories.map((item) => item.catId)));
    },

    currentTabData() {
      return this.uniqueSubCatIds.map((catId) => {
        return this.subCategories.find((sc) => sc.catId == catId);
      }).filter((sc) => sc.parentId == this.tab)
    },

    totalCatAmount() {
      let total = 0;
      this.currentTabData.forEach((r) => total += r.raw_amount);
      return Math.round(total * 100) / 100;
    },

    formattedTotalAmount() {
      return formatAsCurrency(this.totalCatAmount);
    },
  },

  methods: {
    isInCategory(cat, catId) {
      return cat.parentId == catId;
    },

    tableDate(catId) {
      return this.subCategories.filter(this.isInCategory, catId);
    }
  }
}
</script>

<style scoped>
  .chart {
    height: 600px;
  }

  .v-tab {
    justify-content: left;
  }
</style>
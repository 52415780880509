<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="4" md="2">
        <v-select
            :items="historyChartTypes"
            item-value="id"
            v-model="historyChartType"
            label="Chart Type"
        />
      </v-col>
      <v-col cols="8" md="10">
        <v-range-slider
            v-model="dateRange"
            :max="sliderMax"
            :min="0"
            hide-details
            class="align-center"
            label="Date Range"
            inverse-label
        />
      </v-col>
    </v-row>
    <DashChart class="chart" :data="historyChartData" :type="historyChartType"/>
  </v-container>
</template>

<script>
import {hex2rgb} from "../../services/color-functions";
import DashChart from "./Charts/DashChart";

export default {
  name: "CreditTab",
  components: {DashChart},

  data() {
    return {
      dateRange: [],
      historyChartType: 'line',
      historyChartTypes: [
        {id: 'line', text: 'Line'},
        {id: 'vertical-bar', text: 'Vertical Bars'},
        {id: 'horizontal-bar', text: 'Horizontal Bars'}
      ],
      chartColors: [
        "#ff0919",
        "#00ff24",
        "#fff500",
        "#1400ff",
        "#ff00d0",
        "#ff7600",
        "#00f5ff",
        "#bd11ff",
        "#cccccc",
        "#8eccc5",
        "#3b3a54"
      ]
    }
  },

  created() {
    this.$store.dispatch('fetchCreditHistory').then(() => {
      const start = (this.sliderMax - 14) > -1 ? (this.sliderMax - 14) : 0;
      this.dateRange = [start, this.sliderMax];
    });
  },

  computed: {
    historyData() {
      return this.$store.state.charts.history.data || [];
    },

    sliderMax() {
      return this.$store.state.charts.history.labels.length || 0;
    },

    filteredHistoryData() {
      const start = (this.dateRange.length > 0) ? this.dateRange[0] : 0;
      const end = (this.dateRange.length > 1) ? this.dateRange[1] : this.historyData.length;

      return this.historyData.map((h) => {
        return {
          label: h.label,
          color: h.color,
          data: h.data.slice(start, end)
        }
      });
    },

    filteredHistoryLabels() {
      const start = (this.dateRange.length > 0) ? this.dateRange[0] : 0;
      const end = (this.dateRange.length > 1) ? this.dateRange[1] : this.historyData.length;

      return this.historyChartLabels.slice(start, end);
    },

    historyChartDatasets() {
      let dataset = [];

      this.filteredHistoryData.forEach((credit, index) => {
        let color = credit.color ? credit.color : this.chartColors[index];
        dataset.push({
          label: credit.label,
          backgroundColor: this.historyChartType !== 'line' ? hex2rgb(color, 0.6).css : 'transparent',
          borderColor: this.historyChartType !== 'line' ? 'transparent' : hex2rgb(color, 0.6).css,
          data: credit.data
        });
      });

      return dataset;
    },

    historyChartLabels() {
      return this.$store.state.charts.history.labels;
    },

    historyChartData() {
      return {
        labels: this.filteredHistoryLabels,
        datasets: this.historyChartDatasets
      }
    }
  }
}
</script>

<style scoped>
  .chart {
    height: 600px;
  }
</style>
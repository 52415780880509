<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="4" md="2">
        <v-select
            :items="summaryChartTypes"
            item-value="id"
            v-model="summaryType"
            label="Chart Type"
        />
      </v-col>

      <v-col cols="8" md="10">
        <v-range-slider
            v-model="dateRange"
            :max="sliderMax"
            :min="0"
            hide-details
            class="align-center"
            label="Date Range"
            inverse-label
        />
      </v-col>
    </v-row>


    <DashChart class="chart" :data="summaryChartData" :type="summaryType"/>
  </v-container>
</template>

<script>
import {hex2rgb} from "../../services/color-functions";
import DashChart from "./Charts/DashChart";
import colors from 'vuetify/lib/util/colors'

export default {
  name: "SummaryTab",
  components: {DashChart},
  data () {
    return {
      summaryType: 'vertical-bar',
      summaryChartTypes: [
        {id: 'line', text: 'Line'},
        {id: 'vertical-bar', text: 'Vertical Bars'},
        {id: 'horizontal-bar', text: 'Horizontal Bars'}
      ],
      dateRange: []
    }
  },
  created() {
    this.$store.dispatch('fetchSummary').then(() => {
      const start = (this.sliderMax - 14) > -1 ? (this.sliderMax - 14) : 0;
      this.dateRange = [start, this.sliderMax];
    });
  },
  computed: {
    summaryData() {
      return this.$store.state.charts.summary.data;
    },

    sliderMax() {
      return this.$store.state.charts.summary.data.length;
    },

    sliderLabel() {
      return 'test'
    },

    filteredSummaryData() {
      const start = (this.dateRange.length > 0) ? this.dateRange[0] : 0;
      const end = (this.dateRange.length > 1) ? this.dateRange[1] : this.summaryData.length;

      return this.summaryData.slice(start, end);
    },

    summaryLabels() {
      return this.filteredSummaryData.map((d) => d.label);
    },

    summaryIncomes() {
      return this.filteredSummaryData.map((d) => d.revenues);
    },

    summaryCredit() {
      return this.filteredSummaryData.map((d) => d.credit);
    },

    summaryExpenses() {
      return this.filteredSummaryData.map((d) => d.expenses);
    },

    themes() {
      return hex2rgb(this.$themes.primary, 0.6);
    },

    summaryChartData() {
      return {
        labels: this.summaryLabels,
        datasets: [
          {
            label: 'Incomes',
            backgroundColor: this.summaryType !== 'line' ? hex2rgb(colors.teal.darken1, 0.6).css : 'transparent',
            borderColor: this.summaryType !== 'line' ? 'transparent' : hex2rgb('#40e583', 0.6).css,
            data: this.summaryIncomes,
          },
          {
            label: 'Expenses',
            backgroundColor: this.summaryType !== 'line' ? hex2rgb(colors.blue.base, 0.6).css : 'transparent',
            borderColor: this.summaryType !== 'line' ? 'transparent' : hex2rgb(colors.blue.base, 0.6).css,
            data: this.summaryExpenses,
          },
          {
            label: 'Credit',
            backgroundColor: this.summaryType !== 'line' ? hex2rgb(colors.red.base, 0.6).css : 'transparent',
            borderColor: this.summaryType !== 'line' ? 'transparent' : hex2rgb(colors.red.base, 0.6).css,
            data: this.summaryCredit,
          }
        ],
      }
    },
  },
}
</script>

<style scoped>
  .chart {
    height: 600px;
  }
</style>
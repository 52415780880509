<template>
  <div>
    <div class="text-h5">Dashboard</div>

    <v-tabs v-model="tab">
      <v-tab key="summary">Summary</v-tab>
      <v-tab key="credit">Credit</v-tab>
      <v-tab key="expenses">Expenses</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="summary">
        <SummaryTab />
      </v-tab-item>

      <v-tab-item key="credit">
        <CreditTab />
      </v-tab-item>

      <v-tab-item key="expenses">
        <ExpensesTab />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SummaryTab from "./SummaryTab";
import CreditTab from "./CreditTab";
import ExpensesTab from "./ExpensesTab";
export default {
  name: "Dashboard",

  components: {
    SummaryTab,
    CreditTab,
    ExpensesTab
  },

  data() {
    return {
      tab: null
    }
  },
}
</script>

<style scoped>

</style>